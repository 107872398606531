import { Link } from 'gatsby'
import React, { useContext } from 'react'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './hasFavourites.module.css'
import { FavouritesContext } from '../../providers/favouritesProvider'
import { useLocation } from '@reach/router'
import HeartIcon from '../../images/icons/heart'

const HasFavourites = () => {
    const classes = useStyle(defaultClasses)
    const { favourites } = useContext(FavouritesContext)
    const location = useLocation()

    if (
        !favourites ||
        favourites.length === 0 ||
        location.pathname === '/campsites/favourites'
    ) {
        return null // Don't render anything if there are no favourites or on the favourites page
    }

    const itemText = favourites.length === 1 ? 'favourite' : 'favourites'

    return (
        <div className={classes.container}>
            <Link
                to="/campsites/favourites"
                className={classes.link}
                title="See favourites"
            >
                <HeartIcon className="w-4 h-4 inline-block align-middle" />
                <span style={{ marginLeft: '0.5rem' }}>
                    {favourites.length} {itemText}
                </span>
            </Link>
        </div>
    )
}

export default HasFavourites
