import React, { useContext, useEffect } from 'react'
import { useStyle } from '../../hooks/classify'
import { LayoutContext } from '../../providers/layoutProvider'
import { useSiteMetadata } from '../../hooks/useSiteMetadata'
import ScrollTop from '../scrollTop'
import * as defaultClasses from './footer.module.css'
import { Spacer } from '../spacer'
import HasFavourites from '../hasFavourites/hasFavourites'

const Footer = ({ location, parentClasses }) => {
    const classes = useStyle(defaultClasses, parentClasses)
    let layout = useContext(LayoutContext)
    let url = location

    useEffect(() => {
        if (url === '/contact') {
            layout.setLayout({ footer: false })
        } else {
            layout.setLayout({ footer: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])

    const { title, email, phone } = useSiteMetadata()

    let date = new Date().getFullYear()

    return (
        <>
            <Spacer />
            <div className={classes.container}>
                <div className={classes.wrapper}>
                    <div className={classes.footerBaseContainer}>
                        <div className={classes.footerBaseText}>
                            &copy; {date} - {title}. All Rights Reserved.
                            <br />
                            Website by{' '}
                            <a
                                href="https://dawnconcept.com/"
                                title="The Dawn Concept - Web Design Cornwall"
                                className={classes.link}
                            >
                                The Dawn Concept
                            </a>
                        </div>
                    </div>

                    {layout?.layout?.footer && (
                        <div className={classes.footerHeaderWrapper}>
                            <p className={classes.contactLink}>
                                <a
                                    href={'tel:' + phone}
                                    title="Phone"
                                    className={classes.link}
                                >
                                    Call: {phone}
                                </a>
                            </p>
                            <p className={classes.contactLink}>
                                <a
                                    href={'mailto:' + email}
                                    title="Email"
                                    className={classes.link}
                                >
                                    Email: {email}
                                </a>
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <ScrollTop showBelow={250} />
            <HasFavourites />
        </>
    )
}

export default Footer
