import { graphql, useStaticQuery } from 'gatsby'

export const useSiteMetadata = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    type
                    image
                    siteUrl
                    email
                    phone
                    instagram
                    streetAddress
                    addressLocality
                    addressRegion
                    postalCode
                }
            }
        }
    `)

    return data.site.siteMetadata
}
