import React from 'react'
import { useStyle } from '../../hooks/classify'
import { Link } from 'gatsby'

const MenuItems = ({ parentClasses }) => {
    const classes = useStyle(parentClasses)
    return (
        <div className={classes.linksWrapper}>
            <span className={classes.link}>
                <Link to="/" title="Home">
                    Home
                </Link>
            </span>
            <span className={classes.link}>
                <Link to="/holiday-tents" title="Holiday Tents">
                    Holiday Tents
                </Link>
            </span>
            <span className={classes.link}>
                <Link to="/event-tents" title="Event Tents">
                    Event Tents
                </Link>
            </span>
            <span className={classes.link}>
                <Link to="/campsites" title="Campsites">
                    Campsites
                </Link>
            </span>
            <span className={classes.link}>
                <Link to="/sustainability" title="Sustainability">
                    Sustainability
                </Link>
            </span>
            <span className={classes.link}>
                <Link to="/contact" title="Contact us">
                    Contact us
                </Link>
            </span>
        </div>
    )
}

export default MenuItems
