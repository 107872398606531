import React from 'react'

const Icon = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        className={className}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m58.737 34.695-12.26 12.267-.006.006-12 12.008a3.492 3.492 0 0 1-4.942 0l-12-12.008-.006-.008L5.264 34.695c-7.019-7.023-7.019-18.408 0-25.43 7.017-7.021 18.395-7.021 25.412 0l1.325 1.325 1.323-1.325c7.016-7.021 18.394-7.021 25.413 0 7.017 7.022 7.017 18.407 0 25.43zm-1.413-23.971c-6.234-6.259-16.342-6.259-22.575 0l-1.322 1.328a1.99 1.99 0 0 1-2.823 0l-1.322-1.328c-6.234-6.259-16.341-6.259-22.575 0-6.234 6.261-6.234 16.41 0 22.671l12.61 12.663 11.288 11.336a1.99 1.99 0 0 0 2.823 0l11.287-11.336 12.61-12.663c6.233-6.261 6.233-16.41-.001-22.671zm-40.201 3.35a7.998 7.998 0 0 0-7.052 8.842v.002a.998.998 0 1 1-1.986.223c-.62-5.484 3.327-10.434 8.813-11.052a.998.998 0 1 1 .225 1.985z"
        />
    </svg>
)

export default Icon
