import * as React from 'react'
import Header from '../header'
import Footer from '../footer'
import SchemaMarkup from '../schemaMarkup/SchemaMarkup'
import HomeHero from '../homeHero'

const Layout = ({ children, location }) => {
    return (
        <>
            <SchemaMarkup location={location} />
            {location?.pathname === '/' && <HomeHero />}
            <Header location={location?.pathname} />
            {children}
            <Footer location={location?.pathname} />
        </>
    )
}

export default Layout
