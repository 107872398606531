import React from 'react'
import { Link } from 'gatsby'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './logo.module.css'

const Logo = ({ shade, parentClasses }) => {
    const classes = useStyle(defaultClasses, parentClasses)

    const shadeMe = () => {
        if (shade === 'light') {
            return classes.logoLight
        } else {
            return classes.logoDark
        }
    }

    return (
        <Link to="/" className={classes.logoWrapper}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="80 0 115 150"
                className={shadeMe()}
                height="40"
            >
                <polygon points="88.24 152.03 138.42 44.17 188.86 152.03 191.58 150.76 140.07 40.62 155.73 6.96 153.01 5.69 140.04 33.56 140.04 0 137.04 0 137.04 34.15 123.79 5.82 121.07 7.09 136.76 40.62 85.52 150.76 88.24 152.03" />
            </svg>
            <h3 className={shadeMe()}>
                Tylda<span className={classes.stop}>.</span>
            </h3>
        </Link>
        // <Link to="/">
        //     <svg
        //         xmlns="http://www.w3.org/2000/svg"
        //         viewBox="0 0 268.82 285.44"
        //         className={shadeMe()}
        //     >
        //         <g>
        //             <path d="m28.67,183.83c-.42,0-.83.14-1.25.42H2.98c-.28-.28-.65-.42-1.1-.42-.52,0-.96.19-1.33.56-.36.36-.54.8-.54,1.31s.18.96.54,1.33c.38.36.82.54,1.33.54.42,0,.78-.16,1.1-.48h10.88v95.33c-.29.38-.44.77-.44,1.19,0,.51.19.94.56,1.29.38.34.82.52,1.33.52s.92-.18,1.27-.52c.36-.35.54-.78.54-1.29,0-.42-.15-.81-.44-1.19v-95.33h10.73c.46.32.88.48,1.25.48.51,0,.95-.18,1.31-.54s.56-.82.56-1.33-.19-.95-.56-1.31c-.36-.38-.8-.56-1.31-.56Z" />
        //             <path d="m86.38,182.85c-.52,0-.96.19-1.33.56s-.56.81-.56,1.31v.71l-13.08,23.94h-.42l-13.15-23.94c.09-.19.15-.43.15-.71,0-.5-.18-.94-.52-1.31-.35-.38-.78-.56-1.29-.56s-.96.19-1.33.56c-.36.38-.54.81-.54,1.31,0,.83.4,1.4,1.19,1.69l14.33,26.35v69.67c-.28.38-.42.77-.42,1.19,0,.51.18.94.54,1.29.38.34.82.52,1.33.52s.95-.18,1.31-.52c.38-.35.56-.78.56-1.29,0-.38-.16-.77-.48-1.19v-69.67l14.4-26.35c.28-.15.54-.36.79-.65.26-.28.4-.62.4-1.04,0-.5-.19-.94-.56-1.31s-.81-.56-1.31-.56Z" />
        //             <path d="m145.41,280.77c-.47,0-.92.14-1.33.42h-24.62v-95.21c.28-.42.42-.83.42-1.25,0-.5-.18-.94-.52-1.31-.35-.38-.78-.56-1.29-.56s-.96.19-1.33.56c-.36.38-.54.81-.54,1.31,0,.42.14.83.42,1.25v98.06h27.48c.42.28.86.42,1.33.42.51,0,.94-.18,1.29-.52.34-.34.52-.78.52-1.29s-.18-.95-.52-1.31c-.35-.38-.78-.56-1.29-.56Z" />
        //             <path d="m202.11,189.33c-1.14-1.53-2.68-2.76-4.6-3.69-1.92-.93-4.31-1.4-7.19-1.4h-14.69v99.79h14.69c2.88,0,5.27-.46,7.19-1.4,1.93-.93,3.46-2.16,4.6-3.69,1.14-1.53,1.95-3.25,2.44-5.17.48-1.93.73-3.92.73-5.96v-67.29c0-2.04-.24-4.03-.73-5.98-.49-1.96-1.3-3.7-2.44-5.23Zm.31,78.5c0,1.62-.19,3.24-.56,4.83-.36,1.6-1.01,3.02-1.94,4.27-.93,1.25-2.18,2.27-3.73,3.06-1.56.79-3.52,1.19-5.88,1.19h-11.83v-94.08h11.83c2.36,0,4.32.4,5.88,1.19,1.55.78,2.8,1.81,3.73,3.08.93,1.28,1.57,2.72,1.94,4.33.38,1.6.56,3.21.56,4.83v67.29Z" />
        //             <path d="m268.4,282.44v-98.19h-14.69c-2.88,0-5.28.47-7.21,1.4-1.92.93-3.46,2.16-4.62,3.69-1.16,1.53-1.97,3.27-2.44,5.23-.46,1.95-.69,3.94-.69,5.98v81.9c-.28.38-.42.77-.42,1.19,0,.51.17.94.52,1.29.34.34.78.52,1.29.52s.95-.18,1.31-.52c.38-.35.56-.78.56-1.29,0-.42-.14-.81-.42-1.19v-66.81h23.94v66.81c-.28.38-.42.77-.42,1.19,0,.51.19.94.56,1.29.38.34.81.52,1.31.52s.94-.18,1.29-.52c.34-.35.52-.78.52-1.29,0-.42-.14-.81-.42-1.19Zm-2.85-69.67h-23.94v-12.23c0-1.62.19-3.23.56-4.83.38-1.61,1.02-3.05,1.94-4.33.93-1.28,2.17-2.3,3.73-3.08,1.55-.79,3.51-1.19,5.88-1.19h11.83v25.67Z" />
        //             <polygon points="88.24 152.03 138.42 44.17 188.86 152.03 191.58 150.76 140.07 40.62 155.73 6.96 153.01 5.69 140.04 33.56 140.04 0 137.04 0 137.04 34.15 123.79 5.82 121.07 7.09 136.76 40.62 85.52 150.76 88.24 152.03" />
        //         </g>
        //     </svg>
        // </Link>
    )
}

export default Logo
