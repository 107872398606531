// extracted by mini-css-extract-plugin
export var buttonContainer = "homeHero-module--buttonContainer--de0b5 absolute bottom-0 w-full h-ms10 z-10 flex items-center justify-center";
export var chevron = "homeHero-module--chevron--3ac35 animate-bounce h-ms2 fill-white opacity-70";
export var container = "homeHero-module--container--f092e relative mx-auto w-full max-w-[2560px] h-screenMobile h-screen bg-[#b9b1af]";
export var contentContainer = "homeHero-module--contentContainer--b20e4 absolute top-0 h-full w-full z-10 flex items-end";
export var contentWrapper = "homeHero-module--contentWrapper--ba5fa container mx-auto mb-ms10 text-center";
export var image = "homeHero-module--image--e24ad h-full w-full";
export var imageWrapper = "homeHero-module--imageWrapper--0c225 h-full w-full";
export var title = "homeHero-module--title--45e84 text-3xl xl_text-5xl 2xl_md_text-6xl text-opacity-70 text-white";
export var video = "homeHero-module--video--67aa6";
export var videoWrapper = "homeHero-module--videoWrapper--d5dbd";