import './src/styles/global.css'

import React from 'react'
import Layout from './src/components/layout/Layout'
import LayoutProvider from './src/providers/layoutProvider'
import FavouritesProvider from './src/providers/favouritesProvider'

export const wrapRootElement = ({ element }) => (
    <FavouritesProvider>
        <LayoutProvider>{element}</LayoutProvider>
    </FavouritesProvider>
)

export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}
