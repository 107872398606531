import React, { createContext, useState, useEffect } from 'react'

export const FavouritesContext = createContext()

const FavouritesProvider = ({ children }) => {
    const [favourites, setFavourites] = useState([])

    useEffect(() => {
        // Retrieve favourites from local storage
        const storedFavourites = localStorage.getItem('favourites')
        if (storedFavourites) {
            setFavourites(JSON.parse(storedFavourites))
        }
    }, [])

    useEffect(() => {
        // Update local storage when favourites change
        localStorage.setItem('favourites', JSON.stringify(favourites))
    }, [favourites])

    const addToFavourites = (item) => {
        setFavourites((prevFavourites) => [...prevFavourites, item])
    }

    const removeFromFavourites = (item) => {
        setFavourites((prevFavourites) =>
            prevFavourites.filter((fav) => fav !== item)
        )
    }

    // Create the value object to be passed to the context provider
    const favouritesContextValue = {
        favourites,
        addToFavourites,
        removeFromFavourites,
    }

    return (
        <FavouritesContext.Provider value={favouritesContextValue}>
            {children}
        </FavouritesContext.Provider>
    )
}

export default FavouritesProvider
