import React, { useState, useEffect } from 'react'
import { useStyle } from '../../hooks/classify'
import { useScrollLock } from '../../hooks/scrollLock'
import { useSiteMetadata } from '../../hooks/useSiteMetadata'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import * as defaultClasses from './header.module.css'
import Logo from '../logo'
import MenuItems from './menuItems'

const Header = ({ location, parentClasses }) => {
    const classes = useStyle(defaultClasses, parentClasses)
    let url = location
    const { lockScroll, unlockScroll } = useScrollLock()
    const { width } = useWindowDimensions()
    const { email, phone } = useSiteMetadata()

    const [show, setShow] = useState(null)

    useEffect(() => {
        setShow(null)
        unlockScroll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])

    useEffect(() => {
        if (width > 1280) {
            setShow(null)
            unlockScroll()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width])

    const toggleScroll = () => {
        show ? unlockScroll() : lockScroll()
    }

    return (
        <>
            {/* Desktop */}
            <div className={classes.navContainer} id="header">
                <div className={classes.navBar}>
                    <div className={classes.logoWrapper}>
                        <Logo shade={show ? 'light' : 'dark'} />
                    </div>
                    <MenuItems
                        parentClasses={{
                            linksWrapper: classes.linksWrapper,
                            homeLink: classes.homeLink,
                            link: classes.link,
                        }}
                        setShow={setShow}
                    />
                    <div
                        className={
                            show
                                ? `${classes.open} ${classes.menuIcon}`
                                : classes.menuIcon
                        }
                        onClick={() => {
                            setShow(!show)
                            toggleScroll()
                            window[`scrollTo`]({
                                top: document.getElementById('header')
                                    .offsetTop,
                                behavior: `smooth`,
                            })
                        }}
                        aria-hidden="true"
                    >
                        <div></div>
                    </div>
                </div>
            </div>

            {/*Mobile*/}
            <div
                className={
                    show
                        ? `${classes.open} ${classes.mobileMenu}`
                        : classes.mobileMenu
                }
                id="header"
            >
                <div className={classes.mobileMenuInner}>
                    <MenuItems
                        parentClasses={{
                            linksWrapper: classes.mobileLinksWrapper,
                            homeLink: classes.mobileHomeLink,
                            link: classes.mobileLink,
                        }}
                    />
                    <div className={classes.divider} />
                    <p className={classes.mobileLink}>
                        <a href={'mailto:' + email} title="Email">
                            {email}
                        </a>
                    </p>
                    <p className={classes.mobileLink}>
                        <a href={'tel:' + phone} title="Phone">
                            {phone}
                        </a>
                    </p>
                </div>
            </div>
        </>
    )
}

export default Header
