import React, { useState, useEffect } from 'react'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './scrollTop.module.css'

const ScrollTop = ({ showBelow }) => {
    const classes = useStyle(defaultClasses)
    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })

    return (
        <>
            {show && (
                <div className={classes.container}>
                    <button
                        onClick={handleClick}
                        className={classes.button}
                        aria-label="to top"
                        aria-hidden="true"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 86 46.002"
                            className={classes.chevron}
                        >
                            <path d="M5.907,1.004c-1.352-1.338-3.542-1.338-4.894,0c-1.35,1.336-1.352,3.506,0,4.844l39.54,39.15  c1.352,1.338,3.542,1.338,4.894,0l39.54-39.15c1.351-1.338,1.352-3.506,0-4.844s-3.542-1.338-4.894-0.002L43,36.707L5.907,1.004z" />
                        </svg>
                    </button>
                </div>
            )}
        </>
    )
}
export default ScrollTop
