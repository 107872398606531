import React from 'react'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './homeHero.module.css'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import videoDesktop from '../../images/desktop.mp4'
import videoMobile from '../../images/mobile.mp4'

const HomeHero = () => {
    const classes = useStyle(defaultClasses)

    const titleClass = classes.title ? classes.title : ''
    const titleClassCombined = `hOne ${titleClass}`

    const { width } = useWindowDimensions()

    function videoServe() {
        if (width < 640) {
            return videoMobile
        } else {
            return videoDesktop
        }
    }

    const handleClick = () => {
        const element = document.getElementById('header')
        window[`scrollTo`]({
            top: element ? element.offsetTop : 0,
            behavior: `smooth`,
        })
    }

    return (
        <>
            <div className={classes.container}>
                <div className={classes.videoWrapper}>
                    <video
                        className={classes.video}
                        autoPlay
                        loop
                        muted
                        playsInline
                    >
                        <source src={videoServe()} type="video/mp4" />
                    </video>
                </div>
                <div className={classes.contentContainer}>
                    <div className={classes.contentWrapper}>
                        <div className={titleClassCombined}>
                            FREEDOM. LUXURY. SUSTAINABILITY.
                        </div>
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <button
                        onClick={handleClick}
                        className={classes.button}
                        aria-label="read more"
                        aria-hidden="true"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 86 46.002"
                            className={classes.chevron}
                        >
                            <path d="M5.907,1.004c-1.352-1.338-3.542-1.338-4.894,0c-1.35,1.336-1.352,3.506,0,4.844l39.54,39.15  c1.352,1.338,3.542,1.338,4.894,0l39.54-39.15c1.351-1.338,1.352-3.506,0-4.844s-3.542-1.338-4.894-0.002L43,36.707L5.907,1.004z" />
                        </svg>
                    </button>
                </div>
            </div>
        </>
    )
}

export default HomeHero
