import React, { useEffect, useState } from 'react'
import { useSiteMetadata } from '../../hooks/useSiteMetadata'
import { Helmet } from 'react-helmet'

const SchemaMarkup = ({ location }) => {
    const {
        title,
        description,
        type,
        image,
        siteUrl,
        phone,
        email,
        streetAddress,
        addressLocality,
        addressRegion,
        postalCode,
    } = useSiteMetadata()

    // Breadcrumb Schema
    const [breadcrumbList, setBreadcrumbList] = useState([])

    useEffect(() => {
        const path = location.pathname.split('/').filter(Boolean)
        const breadcrumbList = [
            {
                name: 'Home',
                url: process.env.GATSBY_URL, // Replace with your homepage URL
            },
            ...path.map((slug, index) => {
                const urlSegments = path.slice(0, index + 1)
                const url = `${process.env.GATSBY_URL}/${urlSegments.join('/')}`
                const name = slug
                    .replace(/-/g, ' ')
                    .replace(/\b\w/g, (letter) => letter.toUpperCase()) // Capitalize first letter

                return {
                    name,
                    url,
                }
            }),
        ]
        setBreadcrumbList(breadcrumbList)
    }, [location])

    return (
        <>
            {/* Breadcrumb Schema */}
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'BreadcrumbList',
                        itemListElement: breadcrumbList.map((item, index) => ({
                            '@type': 'ListItem',
                            position: index + 1,
                            name: item.name,
                            item: item.url,
                        })),
                    })}
                </script>
            </Helmet>

            {/* Local Business Schema (Home Page Only) */}
            {location.pathname === '/' && (
                <Helmet>
                    <script type="application/ld+json">
                        {JSON.stringify({
                            '@context': 'https://schema.org',
                            '@type': type,
                            name: title,
                            description: description,
                            image: image,
                            logo: image,
                            url: siteUrl,
                            telephone: phone,
                            contactPoint: [
                                {
                                    '@type': 'ContactPoint',
                                    contactType: 'Customer service',
                                    telephone: phone,
                                    email: email,
                                },
                            ],
                            address: {
                                '@type': 'PostalAddress',
                                streetAddress: streetAddress,
                                addressLocality: addressLocality,
                                addressRegion: addressRegion,
                                postalCode: postalCode,
                                addressCountry: 'GB',
                            },
                            openingHoursSpecification: [
                                {
                                    '@type': 'OpeningHoursSpecification',
                                    dayOfWeek: [
                                        'Monday',
                                        'Tuesday',
                                        'Wednesday',
                                        'Thursday',
                                        'Friday',
                                        'Saturday',
                                        'Sunday',
                                    ],
                                    opens: '09:00',
                                    closes: '15:00',
                                },
                            ],
                            areaServed: [
                                {
                                    '@type': 'GeoCircle',
                                    geoMidpoint: {
                                        '@type': 'GeoCoordinates',
                                        latitude: 50.4715,
                                        longitude: -4.7243,
                                    },
                                    geoRadius: 50000,
                                },
                            ],
                            aggregateRating: {
                                '@type': 'AggregateRating',
                                ratingValue: '5',
                                reviewCount: '10',
                            },
                        })}
                    </script>
                </Helmet>
            )}
        </>
    )
}

export default SchemaMarkup
